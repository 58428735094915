import React, {useEffect} from 'react';
import {Row, Col} from 'reactstrap';
import PropTypes from 'prop-types';
import './style.scss';
import tick from '../../../styles/assets/images/sv/tick.svg';
import {t} from "i18next";
import {sendAnalyticsEvents} from "../../../lib/utils/analytics";

const Steps = ({step, handleStepClick, resetData}) => {
    useEffect(() => {
        const eventSent = localStorage.getItem('purchase_event_sent');

        if (step === 5 && !eventSent) {
            sendAnalyticsEvents('purchase_main', 5);

            localStorage.setItem('purchase_event_sent', 'true');
        }
    }, [step]);

  return (
    <Row className={'step no-gutters pl-4 justify-content-between justify-content-lg-start d-sm-flex d-none'}>
      <Col xs={'auto'} className={step > 0 ? 'step__item pl-2 pr-2 active' : 'step__item pl-2 pr-2'}
           onClick={() => {
               if (step > 0) {
                   handleStepClick && handleStepClick(1);
                   resetData && resetData();
               }

               if((step === 5 || step === 3) && resetData) {
                   resetData()
               }
            }
           }>
        <span className={step > 0 ? 'step__count finished' : 'step__count'} >
          {step > 1 ? <img className="step-done-img" src={tick} alt="done" /> : '1'}
        </span>
        <span className={'step__label'}>
          {t('common.layout.pickDate')}
        </span>
      </Col>
      <Col xs={'auto'} className={step > 1 ? 'step__item pl-2 pr-2 active' : 'step__item pl-2 pr-2'}
           onClick={() => {
                   step > 1 && handleStepClick(2)
                   if((step === 5 || step === 3) && resetData) {
                       resetData()
                   }
               }
           }>
      <span className={step > 0 ? 'step__count finished' : 'step__count'} >
          {step > 2 ? <img className="step-done-img" src={tick} alt="done" /> : '2'}
        </span>
        <span className={'step__label'}>
          {t('common.layout.members')}
        </span>
      </Col>
      <Col xs={'auto'} className={step > 2 ? 'step__item pl-2 pr-2 active' : 'step__item pl-2 pr-2'} onClick={step > 2 ? () => handleStepClick(3) : null}>
      <span className={step > 0 ? 'step__count finished' : 'step__count'} >
          {step > 3 ? <img className="step-done-img" src={tick} alt="done" /> : '3'}
        </span>
        <span className={'step__label'}>
          {t('common.layout.confirmation')}
        </span>
      </Col>
      <Col xs={'auto'} className={step > 3 ? 'step__item pl-2 pr-2 active' : 'step__item pl-2 pr-2'} >
        <span className={step > 0 ? 'step__count finished' : 'step__count'} >
          {step > 4 ? <img className="step-done-img" src={tick} alt="done" /> : '4'}
        </span>
        <span className={'step__label'}>
          {t('common.layout.payment')}
        </span>
      </Col>
      <Col xs={'auto'} className={step > 4 ? 'step__item pl-2 pr-2 active' : 'step__item pl-2 pr-2'} onClick={step > 4 ? () => handleStepClick(5) : null}>
        <span className={step > 0 ? 'step__count finished' : 'step__count'} >
          {step > 5 ? <img className="step-done-img" src={tick} alt="done" /> : '5'}
        </span>
        <span className={'step__label'}>
          {t('common.layout.finish')}
        </span>
      </Col>
    </Row>
  )
}

export default Steps;

Steps.propTypes = {
  step: PropTypes.number,
  handleStepClick: PropTypes.func
}