const mindboxEvents = require("./mindboxEvents");

const AUTH_FROM_STEP_EVENT = 'authFromStepEvent';
const AUTH_FROM_MENU_EVENT = 'authFromMenuEvent';
const ON_STEP_2_EVENT = 'onStep2Event';
const ON_CREATE_ORDER_EVENT = 'onCreateOrderEvent';
const ON_CREATE_PAYMENT_EVENT = 'onCreatePaymentEvent';
const ON_AFTER_PAYMENT_EVENT = 'onAfterPaymentEvent';

const eventTypesRu = {
    [AUTH_FROM_STEP_EVENT]: mindboxEvents.AuthorizeCustomer,
    [AUTH_FROM_MENU_EVENT]: mindboxEvents.AuthorizeCustomer,
    [ON_STEP_2_EVENT]: 'Брошенная корзина #eventType# - Шаг 1 - Данные о выбранном товаре',
    [ON_CREATE_ORDER_EVENT]: mindboxEvents.OrderAction,
    [ON_CREATE_PAYMENT_EVENT]: 'Брошенная корзина #eventType# - Шаг 3 - Данные заказа',
    [ON_AFTER_PAYMENT_EVENT]: mindboxEvents.CreateAuthorizedOrder,
}

const eventTypesEn = {
    [AUTH_FROM_STEP_EVENT]: 'Authorization after picked product',
    [AUTH_FROM_MENU_EVENT]: 'Authorization from menu',
    [ON_STEP_2_EVENT]: 'Abandoned cart #eventType# - Step 1 - Data of picked product',
    [ON_CREATE_ORDER_EVENT]: 'Abandoned cart #eventType# - Step 2 - Member data',
    [ON_CREATE_PAYMENT_EVENT]: 'Abandoned cart #eventType# - Step 3 - Order details',
    [ON_AFTER_PAYMENT_EVENT]: 'Abandoned cart #eventType# - Step 4 - Payment',
}

const eventTypesLangAdapter = {
    en: eventTypesEn,
    ru: eventTypesRu,
}

module.exports = {
    eventTypes: {
        AUTH_FROM_STEP_EVENT,
        AUTH_FROM_MENU_EVENT,
        ON_STEP_2_EVENT,
        ON_CREATE_ORDER_EVENT,
        ON_CREATE_PAYMENT_EVENT,
        ON_AFTER_PAYMENT_EVENT,
    },
    eventTypesLangAdapter
}
