import React, {useEffect, useState} from "react";
import { Alert, Input, Label } from 'reactstrap';
import "./style.scss";
import { useForm } from "react-hook-form";
import FormButton from "../../generic/buttons/main";
import Divider from "../../generic/Divider";
import { usePublicEndpoints } from "../../../lib/api/usePublicEndpoints";
import {convertToISO} from "../../../lib/utils/format";
import { NumberParam } from 'use-query-params';
import MemberCard from "./MemberCard";
import {isFieldEmpty, validateFields} from "../../../lib/utils/helpers/formsHelpers";
import dayjs from "dayjs";
import {preparePeopleData} from "../../../lib/utils/helpers";
import useQueryParamWithDefault from "../../../lib/utils/hooks/useQueryParamWithDefault";
import AgeConsent from "../../common/AgeConsent";
import {parseErrors, RESPONSE_ERROR_MESSAGES} from "../../../lib/utils/errors";
import {useLoaderState} from "../../../lib/api/loaderState";
import {t} from "i18next";
import { useEventRecordsApi } from '../../../lib/api/eventRecords';
import { eventTypes } from 'sv-common/constants/externalEvents';
import { useInjection } from 'brandi-react';
import { AdventureContainerModelStoreToken } from '../AdventureContainer/model';
import { observer } from 'mobx-react-lite';
import { ArrivalModelStoreToken } from '../../../entities/arrivals/model';
import { GlobalSaleModelToken } from '../../../lib/models/availablePriceFromSale';
import { getCookie } from '../../../lib/utils/cookies';
import Installment from "../../common/Installment";
import {sendAnalyticsEvents} from "../../../lib/utils/analytics";

const MembersScreen = ({
    peopleCount,
    finalize,
    orderId,
    sendPaymentType,
    arrivalURL,
    step,
}) => {
    const model = useInjection(AdventureContainerModelStoreToken);
    const arrivalM = useInjection(ArrivalModelStoreToken);

    const arrival = arrivalM.item;
    const arrivals = arrivalM.list;

    const globalSaleM = useInjection(GlobalSaleModelToken);
    const { record } = useEventRecordsApi();
    const [shirtSizes, setShirtSizes] = useState([]);
    const [renderedArrival, setRenderedArrival] = useState(arrival);
    const { getShirtSizes, createOrder } = usePublicEndpoints();
    const [paymentSize, setPaymentSize] = useState(100);
    const [paymentOptions, setPaymentOptions] = useState({
        option20: arrival?.partial_payment_option_20 || false,
        option50: arrival?.partial_payment_option_50 || false,
    });
    const [isArrivalOverbooked, setIsArrivalOverbooked] = useState(false);
    const [partPay, setPartPay] = useQueryParamWithDefault("partPay", NumberParam, 100);

    const form = useForm({mode: "onBlur", reValidateMode: "onChange"});
    const {handleSubmit, setError, getValues, errors, register} = form;

    const {setIsLoading} = useLoaderState();

    useEffect(() => {
        let chosenArrival = null;
        if (arrivals.length && !arrival) {
            chosenArrival = arrivals.filter((item) => item.url === arrivalURL)[0];
        } else if (arrival) {
            chosenArrival = arrival;
        }

        setRenderedArrival(() => chosenArrival);
        const updatedOptions = {
            option20: chosenArrival?.partial_payment_option_20 || false,
            option50: chosenArrival?.partial_payment_option_50 || false,
        };
        setPaymentOptions(() => updatedOptions);
    }, []);
    useEffect(() => {
        setIsLoading(() => true);
        getShirtSizes()
            .then((sizes) => setShirtSizes(() => sizes))
            .catch((e) => console.log(e))
            .finally(() => setIsLoading(() => false));
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const isPartialAvailable = !arrival.visible_installment && (renderedArrival?.start_date
        ? dayjs()
            .startOf('day')
            .isBefore(dayjs(renderedArrival.start_date).startOf('day').subtract(40, 'd'))
        : false);

    const onCreate = () => {
        model.peopleData.map((humanData) => {
            humanData.passport_date_foreign = convertToISO(humanData.passport_date_foreign)
            humanData.passport_date_ru = convertToISO(humanData.passport_date_ru)
            humanData.birthdate = convertToISO(humanData.birthdate)
        });
        let data = {
            participants: model.peopleData,
            product: "arrivals",
            productId: renderedArrival.id,
            total_value: globalSaleM.calculatePrice(renderedArrival, peopleCount) || renderedArrival.price_value,
            payment_currency: renderedArrival.price_currency,
            full_book_discount_applied: false,
            coachless_discount_applied: false,
            team_book_discount_applied: !!model.isTeamBookActive,
            ym_client_id: getCookie(`_ym_uid`),
            installment: model.installment?.promocode
        };
        if ((paymentSize !== 100 || partPay !== 100) && isPartialAvailable) data.partial_payment_option = partPay;
        if (!isPartialAvailable) delete data.partial_payment_option;
        if (orderId) {
            data.step = step
        }
        const validateCondition = validateFields(data.participants, form, {endDate: arrival.end_date})
            && !isFieldEmpty('birthdate', data.participants, setError);
        if(validateCondition) {
            setIsLoading(true);
            createOrder(data)
                .then((res) => {
                    finalize(model.peopleData, res.orderId);
                })
                .catch((e) => {
                    const eMessage = e.response?.data?.message;
                    const error = parseErrors(eMessage);
                    if (error === RESPONSE_ERROR_MESSAGES.PHONE_TAKEN){
                        const participantId = model.peopleData.findIndex(p => p.phone === eMessage.split(': ')[1]);
                        setError(`phone-${participantId}`, {type: 'phoneTaken', message: t('errors.phoneAlreadyUses')})
                    }
                    else if (error === RESPONSE_ERROR_MESSAGES.OVERBOOKED) setIsArrivalOverbooked(true)
                })
                .finally(() => setIsLoading(() => false));
        }
    };

    useEffect(() => {
        if (arrival.participants) {
            model.setPeopleData(arrival.participants);
        } else if (!model.peopleData.length) {
            let startPeopleData = [];
            startPeopleData.push(model.defaultValues);
            for (let i = 0; i < peopleCount - 1; i++) startPeopleData.push({});

            model.setPeopleData(startPeopleData);
        } else if (peopleCount < model.peopleData.length) {
            model.setPeopleData(model.peopleData.slice(0, peopleCount - model.peopleData.length));
        } else if (peopleCount > model.peopleData.length) {
            model.setPeopleData(model.peopleData.concat(...new Array(peopleCount - model.peopleData.length).fill({})));
        } 

        arrival.id && record(eventTypes.ON_STEP_2_EVENT, { peopleCount, ...arrival });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const handlePaymentClick = (e) => {
        setPaymentSize(+e.target.value);
        sendPaymentType && sendPaymentType(+e.target.value);
        setPartPay(e.target.value);
    };

    const peopleForms = model.peopleData.map((item, index) => (
        <div key={index} data-testid={`member-${index}-container`}>
            <MemberCard
              arrival={renderedArrival}
              shirtSizes={shirtSizes}
              form={form}
              index={index}
              updatePeopleData={({target}) => {
                  model.setPeopleData(preparePeopleData(target.name, target.value, model.peopleData))
              }}
            />
            <Divider />
        </div>
    ));



    return (
        <form data-testid='members-form' className={"members-form"} onSubmit={handleSubmit(onCreate)}>
            {peopleForms}
            {arrival.check_age_required &&
                <AgeConsent register={register} error={errors.ageConsent} value={getValues().ageConsent} required
                            age={arrival.check_age_value}
                />
            }
            {arrival.adventure_id.show_restrictions && (arrival.adventure_id.restrictions || []).map((v,index) => (
              <Alert key={index} color="danger" className='mr-2 ml-2'>{v}</Alert>
            ))}
            <div className="radio-group">
                {isPartialAvailable && paymentOptions?.option20 && (
                    <div className="radio-item">
                        <Input
                            type="radio"
                            defaultChecked={partPay === 20}
                            name="payment_type"
                            id="payment_20"
                            className="radio-payment"
                            value={20}
                            onClick={handlePaymentClick}
                        />
                        <Label htmlFor="payment_20">{t('travel.20%')}<span className="h6">{arrival.adventure_id.is_camp || arrival.adventure_id.is_charter ? t('travel.20%_extra') : t('travel.20%_extra_adventures')}</span></Label>
                    </div>
                )}
                {isPartialAvailable && paymentOptions?.option50 && (
                    <div className="radio-item">
                        <Input
                            type="radio"
                            defaultChecked={partPay === 50}
                            name="payment_type"
                            id="payment_50"
                            className="radio-payment"
                            value={50}
                            onClick={handlePaymentClick}
                        />
                        <Label htmlFor="payment_50">{t('travel.50%')}<span className="h6">{arrival.adventure_id.is_camp || arrival.adventure_id.is_charter ? t('travel.50%_extra') : t('travel.50%_extra_adventures')}</span></Label>
                    </div>
                )}
                {isPartialAvailable && (paymentOptions?.option20 || paymentOptions?.option50) && (
                    <div className="radio-item">
                        <Input
                            type="radio"
                            defaultChecked={partPay === 100}
                            name="payment_type"
                            id="payment_100"
                            className="radio-payment"
                            value={100}
                            onClick={handlePaymentClick}
                        />
                        <Label htmlFor="payment_100">{t('travel.100%')}</Label>
                    </div>
                )}
            </div>
            {isPartialAvailable && (paymentOptions?.option20 || paymentOptions?.option50) && <Divider />}
            {isArrivalOverbooked &&
            <p className="member">{t('errors.someoneAlready')}</p>
            }
            {arrival.visible_installment && arrival.installment.length &&
                <Installment installment={arrival.installment} setInstallment={model.setInstallment}/>
            }
            <FormButton
                data-testid='next-submit'
                type="submit"
                value={t('common.next')}
                disabled={isArrivalOverbooked}
                onClick={() => {
                    sendAnalyticsEvents('name_main', 2);
                }}
            />
        </form>
    );
};

export default observer(MembersScreen);