import './style.scss';
import useUserData from "../../../lib/utils/hooks/useUserData";
import {t} from "i18next";
import FormButton from "../../generic/buttons/main";
import SubscriptionDetails from "../../generic/SubscriptionDetails";

const SeasonTicketProfile = () => {
    const userData = useUserData();
    const {deposit_sum} = userData;

    return (
        <>
            <h1 className='profile-title'>{t('profile.certificatesSubscriptions.subscription')}</h1>
            {!deposit_sum &&
                <>
                    <p>{t('profile.certificatesSubscriptions.noAvailableSubscriptions')}</p>
                    <FormButton
                        value={t('profile.certificatesSubscriptions.buy')}
                        type="button"
                        className="small-button"
                        onClick={() => window.location.href = '/abonement/'}
                    />
                </>
            }
            {!!deposit_sum &&
                <SubscriptionDetails subscriptionData={userData} />
            }
        </>
    )
}

export default SeasonTicketProfile