import React, { useEffect, useState } from 'react';
import {Link} from 'react-router-dom';
import { Row, Label, Input, Button } from 'reactstrap';
import { useForm } from "react-hook-form";
import emailTick from '../../../../styles/assets/images/sv/email-tick.svg';
import Logo from '../../../layout/ Logo';
import './style.scss';
import i18next from "i18next";
import { useInjection } from 'brandi-react';
import { PasswordRestoreFormModelStoreToken } from './model';
import { observer } from 'mobx-react-lite';
import { FormRouterModelStoreToken } from '../model';
import { FORM_STEPS } from '../../../../lib/utils/constants';
import {useCaptcha} from '../../../../lib/utils/hooks/useCaptcha'

const Restore = () => {
  const [timer, setTimer] = useState(0);
  const [isSubmit, setIsSubmit] = useState(false)
  const [generateCaptcha, CaptchaDiv] = useCaptcha();
  const model = useInjection(PasswordRestoreFormModelStoreToken);
  const formRouterModel = useInjection(FormRouterModelStoreToken);
  const { register, errors, handleSubmit, getValues } = useForm({
    mode: "onChange",
    reValidateMode: "onChange"
  });

  const { t } = i18next;
  const values = getValues();

  const handleChange = () => {
    if (errors.email) {
      model.setError('');
    }
  }

  useEffect(() => {
    if(timer > 0) {
        const interval = setInterval(() => setTimer(prev => prev - 1), 1000)
        return () => clearInterval(interval);
    }
  }, [timer])

  const onSubmit = (data) => {
    if (timer > 0) {
        setIsSubmit(false)
        return
    }

    model.onSubmit(data.email, generateCaptcha); 
    setIsSubmit(true);
    setTimer(60);
  };

  return(
    <>{CaptchaDiv}
        <div id={'login'}>
        <div className={'login-container'}>
            <Logo />
            <div className="login-sub-logo login-text">
            {t('common.forgotPassword.title')}
            </div>
            <form onSubmit={handleSubmit(onSubmit)} action="">
                <Row className="no-gutters login-row">
                    <img alt="" src={emailTick} className={values.email && !errors.email && model.error !== "emailError" !== "exist@mail.com" ? "email-correct" : "email-correct d-none"} />
                    <Label htmlFor="email" className="login-label">
                    {t('common.fields.email.label')}
                    </Label>
                    <Input
                    className={!!errors.email || model.error === "emailError" ? "login-input has-error" : "login-input"}
                    type="text"
                    id="email"
                    name="email"
                    onChange={handleChange}
                    innerRef={register({
                        required: true,
                        pattern: /^\S+@\S+\.\S+$/
                    })}
                    />
                    <span className={"error-label w-100"}>
                    {errors.email?.type === "required" ? t('common.fields.email.validation.required') : ""}
                    {errors.email?.type === "pattern" ? t('common.fields.email.validation.pattern') : ""}
                    {model.error === "emailError" &&
                    <>
                        <span>{t('common.forgotPassword.accountNotExists')}. </span>
                        <Row className={'d-flex justify-content-center restore-success'}>
                            <Link className={'login-link'} to="/register/">
                            {t('common.forgotPassword.signUpSuggestion')}
                            </Link>
                        </Row>
                    </>}
                    </span>
                </Row>
                <Row className={'no-gutters'}>
                    <Button type="submit" className={'w-100 reg-button'} color={'primary'}>
                    {t('common.buttons.recoverPassword')}
                    </Button>
                </Row>
                {model.isEmailSent && <div className="restore-success">
                    {isSubmit 
                        ? t('common.forgotPassword.restoreSuccess') 
                        : (timer > 0) && t('common.forgotPassword.timerSent', {timerCount: timer})}
                </div> }
            </form>
            <button className="login-link return" onClick={() => formRouterModel.setStep(FORM_STEPS.PASSWORD_LOGIN)}>
            {t('common.forgotPassword.backToLogIn')}
            </button>
        </div>
        </div>
    </>
  )
}

export default observer(Restore);