const useOwnBoatDiscount = (setIsOwnBoatVisible, bookFullBoat, sendData, calculateBookAvailability) => {
    const handle = (checked) => {
        setIsOwnBoatVisible(() => !checked)
        if (checked) {
            bookFullBoat();
            sendData({
                peopleAmount: calculateBookAvailability(),
                isFullBookChecked: true,
            });
        }
        else {
            sendData({ isFullBookChecked: false });
        }
    }

    return { handle };
}

export default useOwnBoatDiscount;