let currentLocation = window.location.href;
const paramsHandler = {
    date_main: { URL_Date: currentLocation, "277269962_to-parse": `${currentLocation};${Date.now()}` },
    name_main: { URL_Name: currentLocation, "289167522_to-parse": `${currentLocation};${Date.now()}` },
    confirm_main: { URL_Confirm: currentLocation, "289167723_to-parse": `${currentLocation};${Date.now()}` },
    purchase_main: { URL_Purchase: currentLocation, "277269964_to-parse": `${currentLocation};${Date.now()}` }
};

export const sendAnalyticsEvents = (target, step) => {
    sendAnalyticsEvent('yandex', target);
    sendAnalyticsEvent('facebook', null, step);
    sendAnalyticsEvent('vk_ads_1', null, step);
    sendAnalyticsEvent('vk_ads_2', null, step);
    sendAnalyticsEvent('vk_targeting_1', null, step);
    sendAnalyticsEvent('vk_targeting_2', null, step);
}

const sendAnalyticsEvent = (platform, target, step) => {
    switch (platform) {
        case 'yandex':
            sendYandexMetrikaEvent(target);
            break;
        case 'facebook':
            sendFacebookEvent(step);
            break;
        case 'vk_ads_1':
            sendVkAds1Event(step);
            break;
        case 'vk_ads_2':
            sendVkAds2Event(step);
            break;
        case 'vk_targeting_1':
            sendVkTargeting1Event(step);
            break;
        case 'vk_targeting_2':
            sendVkTargeting2Event(step);
            break;
        default:
            console.warn(`Unknown platform: ${platform}`);
    }
};

const sendYandexMetrikaEvent = (target) => {
    const MAIN_METRIKA_ID = process.env.REACT_APP_YA_ID;

    if (window.ym) {
        window.ym(MAIN_METRIKA_ID, 'reachGoal', target, paramsHandler[target] || {});
    } else {
        console.warn('Yandex Metrika is not loaded');
    }
};

const sendFacebookEvent = (step) => {
    if (window.fbq) {
        window.fbq('track', 'ViewContent', {
            content_name: 'pageview',
            content_category: `step_${step}`,
            value: 1.00,
            currency: 'EUR'
        });
    } else {
        console.warn('Facebook Pixel is not loaded');
    }
};

const sendVkAds1Event = (step) => {
    const VK_ADS_1_ID = process.env.REACT_APP_VK_ADS_1_ID;
    const _tmr = window._tmr || (window._tmr = []);

    if (_tmr) {
        _tmr.push({ id: VK_ADS_1_ID, type: 'reachGoal', goal: `pageview-step_${step}` });
    } else {
        console.warn('VK Ads (Кабинет 1) is not loaded');
    }
};

const sendVkAds2Event = (step) => {
    const VK_ADS_2_ID = process.env.REACT_APP_VK_ADS_2_ID;
    const _tmr = window._tmr || (window._tmr = []);

    if (_tmr) {
        _tmr.push({ type: 'reachGoal', id: VK_ADS_2_ID, goal: `pageview-step_${step}` });
    } else {
        console.warn('VK Ads (Кабинет 2) is not loaded');
    }
};

const sendVkTargeting1Event = (step) => {
    const VK_TARGETING_1_ID = process.env.REACT_APP_VK_TARGETING_1_ID;

    if (window.VK && window.VK.Retargeting) {
        window.VK.Retargeting.Init(VK_TARGETING_1_ID);
        window.VK.Retargeting.Event(`pageview-step_${step}`);
    } else {
        console.warn('VK Targeting (Старый кабинет 1) is not loaded');
    }
};

const sendVkTargeting2Event = (step) => {
    const VK_TARGETING_2_ID = process.env.REACT_APP_VK_TARGETING_2_ID;

    if (window.VK && window.VK.Retargeting) {
        window.VK.Retargeting.Init(VK_TARGETING_2_ID);
        window.VK.Retargeting.Event(`pageview-step_${step}`);
    } else {
        console.warn('VK Targeting (Старый кабинет 2) is not loaded');
    }
};