import React, {useState, useEffect} from 'react';
import FormButton from '../../generic/buttons/main';
import { calculatePrice, calculateDiscount } from '../../../lib/utils/helpers';
import {t} from "i18next";

const PriceBlock = ({onSubmit, price, currency, discount, eventData, order, promocodeApplied, peopleAmount,
                    isButtonDisabled, buttonValue, currencies}) => {
  const [discountType, setDiscountType] = useState(null);
  const normalizeCurrency = (currencyKey) => currencies && currencies.filter(item => item.key === currencyKey)[0];
  useEffect(() => {
    let discount = calculateDiscount(eventData?.coachless_discount, eventData?.full_book_discount, eventData?.own_boat_discount, price, peopleAmount);
    setDiscountType(() => discount.text);
  }, [eventData])
  return (
    <div className="price-info d-block d-lg-none">
      <div className="price-left-col">
        <div className="price-label card-label">
            {t('common.price')}
        </div>
        <div className="price-row">
          {!!discount && 
          <div className="old-price">
            {`${Math.round(price * peopleAmount) ||
                order?.totalValue || 0} ${normalizeCurrency(currency)?.sign || ''}`}
          </div>}
          <div className="price">
            {`${calculatePrice(null, promocodeApplied, price, peopleAmount, discount)} ${
                normalizeCurrency(currency)?.sign}`}
          </div>
        </div>
        {discountType && 
        <div className="price-discount-label">
          {discountType}
        </div> }
        
        <div className="price-right-col ">
                {(price > 0 && currency === "EUR") && (
                <div className="price-localized price-mobile">
                  {price * 90}{" "}
                  <span>P</span>
                </div>
              )}
        </div>
      </div>
      <FormButton
        value={buttonValue}
        onClick={onSubmit || (eventData.instructor?.available_sits < peopleAmount)} 
        disabled={isButtonDisabled}
      />
    </div>
  )
} 


export default PriceBlock;