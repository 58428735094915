import React, {useState} from 'react';

import {Button, Input, Label, Row} from 'reactstrap';
import {useForm} from "react-hook-form";
import {StringParam, useQueryParam} from 'use-query-params';
import invisible from '../../styles/assets/images/sv/pass-invisible.svg';
import visible from '../../styles/assets/images/sv/pass-visible.svg';
import emailTick from '../../styles/assets/images/sv/email-tick.svg';
import {useApi} from '../../lib/api/useApi';
import {useRedirect} from "../../lib/utils/hooks/useRedirect";
import Logo from "../layout/ Logo";
import {useLoaderState} from "../../lib/api/loaderState";
import {t} from "i18next";

const NewPassword = () => {
  const {redirectTo} = useRedirect();
  const [error, setError] = useState('');
  const [isPassInvisible, setPassInvisible] = useState(true);
  const [code] = useQueryParam("code", StringParam);
  const { register, errors, handleSubmit, getValues } = useForm({
    mode: "onChange",
    reValidateMode: "onChange"
  });
  const values = getValues();
  const {resetPassword} = useApi();
  const {setIsLoading} = useLoaderState();

    const onSubmit = (data) => {
    setIsLoading(() => true);
    resetPassword(code, data.password)
      .then(() => {
        setError(() => '');
        redirectTo();
      })
    .catch(error => {
        let errorStatus = error.response?.data?.statusCode;
        if (errorStatus === 403) {
            setError('invalidToken')
        } else {
            setError('unknownError');
        } 

        setTimeout(() => {
            redirectTo();
        }, 8000)
    })
    .finally(() => {
      setIsLoading(() => false);
    });
  };

  return(
    <div id={'login'} className={'overlay w-100 h-100 d-flex justify-content-center flex-column login'}>
      <div className={'login-container'}>
        <div className="login-logo">
            <Logo />
        </div>
        {error ? 
            <div className="login-sub-logo login-text">
                {error === 'invalidToken' ? t('newPassword.error.invalidToken') : t('newPassword.error.unknownError')}
            </div>
            : <>
                <div className="login-sub-logo login-text">
                    {t('newPassword.description')}
                </div>
                <form onSubmit={handleSubmit(onSubmit)} action="">
                    <Row className="no-gutters login-row">
                        <img alt="" src={emailTick} className={values.email && !errors.email && error !== "emailError" !== "exist@mail.com" ? "email-correct" : "email-correct d-none"} />
                        <Label htmlFor="email" className="login-label">
                            {t('newPassword.label')}
                        </Label>
                        <Input
                        type={isPassInvisible ? 'password' : 'text'}
                        id="password" 
                        className={!!errors.password ? "login-input has-error" : isPassInvisible ? "login-input pass-invisible" : "login-input"}
                        name="password"
                        placeholder={t('newPassword.placeholder')}
                        innerRef={register({
                            required: true,
                            minLength: 5
                        })}
                        />
                        <span className={"error-label"}>
                        {errors.password?.type === "required" ? t('inputs.required') : ""}
                        {errors.password?.type === "minLength" ? t('common.fields.password.validation.minLength') : ""}
                        </span>
                        <img className={isPassInvisible ? 'login-pass-toggle' : 'login-pass-toggle visible'} src={isPassInvisible ? invisible : visible} alt="" onClick={() => setPassInvisible(!isPassInvisible)}/>
                    </Row>
                    <Row className={'no-gutters'}>
                        <Button type="submit" className={'w-100 reg-button'} color={'primary'}>{t('newPassword.saveAndContinue')}</Button>
                    </Row>
                </form>
            </>
        }
      </div>
    </div>
  )
}

export default NewPassword;
