import React, {useEffect, useState} from "react";
import { useForm } from "react-hook-form";
import FormButton from "../../../generic/buttons/main";
import Divider from "../../../generic/Divider";
import { usePublicEndpoints } from "../../../../lib/api/usePublicEndpoints";
import PropTypes from "prop-types";
import { dateToISO } from "../../../../lib/utils/format";
import { NumberParam, StringParam, useQueryParams, withDefault } from 'use-query-params';
import MemberCard from "./MemberCard";
import PartialPayment from "./PartialPayment";
import AgeConsent from '../../../common/AgeConsent';
import _ from 'lodash';
import {isFieldEmpty} from "../../../../lib/utils/helpers/formsHelpers";
import {parseErrors, RESPONSE_ERROR_MESSAGES} from "../../../../lib/utils/errors";
import {useLoaderState} from "../../../../lib/api/loaderState";
import {t} from "i18next";
import { useEventRecordsApi } from '../../../../lib/api/eventRecords';
import { eventTypes } from 'sv-common/constants/externalEvents';
import { useInjection } from 'brandi-react';
import { GlobalSaleModelToken } from '../../../../lib/models/availablePriceFromSale';
import dayjs from 'dayjs';
import { getCookie } from '../../../../lib/utils/cookies';
import Installment from "../../../common/Installment";
import {sendAnalyticsEvents} from "../../../../lib/utils/analytics";

const MembersScreen = ({practice, firstUserFields, peopleData, setPeopleData, setInstallment, installmentPromo}) => {
    const { record } = useEventRecordsApi();
    const [shirtSizes, setShirtSizes] = useState([]);
    const { getShirtSizes, createOrder } = usePublicEndpoints();
    const {installment, visible_installment} = practice;
    const globalSaleM = useInjection(GlobalSaleModelToken);

    const [query, setQuery] = useQueryParams({
        step: withDefault(NumberParam, 1),
        people: NumberParam,
        practice: StringParam,
        partPay: withDefault(NumberParam, 100)
    });
    const [paymentOptions] = useState({
        option20: practice.partialPaymentOption20 || false,
        option50: practice.partialPaymentOption50 || false,
    });
    const [isArrivalOverbooked, setIsArrivalOverbooked] = useState(false);

    const {setIsLoading} = useLoaderState();

    const isISODate = (str) => /^\d{4}-\d{2}-\d{2}$/.test(str);
    useEffect(() => {
        const peopleCount = query.people || 1;
        setIsLoading(() => true);
        getShirtSizes()
            .then((sizes) => setShirtSizes(() => sizes))
            .catch((e) => console.log(e))
            .finally(() => setIsLoading(() => false));

        if (practice.participants) {
            setPeopleData(() => practice.participants);
        } else if (!peopleData.length) {
            let startPeopleData = [];
            startPeopleData.push(firstUserFields);
            for (let i = 0; i < peopleCount - 1; i++) startPeopleData.push({});

            setPeopleData(startPeopleData);
        } else if (peopleCount < peopleData.length) {
            setPeopleData(peopleData.slice(0, peopleCount - peopleData.length));
        } else if (peopleCount > peopleData.length) {
            setPeopleData(peopleData.concat(...new Array(peopleCount - peopleData.length).fill({})));
        } 

        practice.id && record(eventTypes.ON_STEP_2_EVENT, { peopleCount: query.people, ...practice, is_school: true, adventure_id: practice.adventureData, start_date: practice.startDate });
    }, []);

    const onCreate = () => {
        const convertToISO = date => !isISODate(date) ? dateToISO(date) : date
        peopleData.map(humanData => {
            humanData.passport_date_foreign = convertToISO(humanData.passport_date_foreign)
            humanData.passport_date_ru = convertToISO(humanData.passport_date_ru)
            humanData.birthdate = convertToISO(humanData.birthdate)
        });
        let data = {
            participants: peopleData,
            product: "arrivals",
            productId: practice.id,
            total_value: globalSaleM.calculatePrice(practice, query.people) || practice.priceValue,
            payment_currency: practice.priceCurrency,
            ym_client_id: getCookie(`_ym_uid`),
            installment: installmentPromo
        };
        if (query.partPay !== 100) data.partial_payment_option = query.partPay;
        if (!isFieldEmpty('birthdate', data.participants, setError)) {
            setIsLoading(true);
            createOrder(data)
                .then(res => {
                    setQuery({step: 3, orderId: res.orderId})
                })
                .catch(e => {
                    const eMessage = e.response?.data?.message;
                    const error = parseErrors(eMessage);
                    if (error === RESPONSE_ERROR_MESSAGES.PHONE_TAKEN){
                        const participantId = peopleData.findIndex(p => p.phone === eMessage.split(': ')[1]);
                        setError(`phone-${participantId}`, {type: 'phoneTaken',
                            message: t('erros.phoneAlreadyUses')})
                    }
                    else if (error === RESPONSE_ERROR_MESSAGES.OVERBOOKED) setIsArrivalOverbooked(true)
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    };

    const updatePeopleData = (e) => {
        let currentPeopleData = peopleData;
        let { name, value } = e.target;
        const personOrder = name.substr(name.length - 1);
        const propertyName = name.substring(0, name.length - 2);
        currentPeopleData[personOrder] = {
            ...currentPeopleData[personOrder],
            [propertyName]: value,
        };
        setPeopleData(() => currentPeopleData);
    };

    const form = useForm({mode: "onChange", reValidateMode: "onChange"});
    const { handleSubmit, getValues, errors, register, setError } = form;
    const handlePaymentClick = (e) => {
        setQuery({
            partPay: e.target.value,
        })
    };

    const values = getValues();

    const isPartialAvailable = practice?.startDate
      ? dayjs()
        .startOf('day')
        .isBefore(dayjs(practice.startDate).startOf('day').subtract(30, 'd'))
      : false;

    return (
        <form className={"members-form"} onSubmit={handleSubmit(onCreate)}>
            {!_.isEmpty(peopleData) && peopleData.map((item, index) => (
                <div key={index}>
                    <MemberCard peopleCount={query.people} practice={practice} peopleData={peopleData}
                                shirtSizes={shirtSizes} form={form} updatePeopleData={updatePeopleData}
                                index={index} firstUserFields={index === 0 ? firstUserFields : {}} />
                    <Divider />
                </div>
            ))}
            {
                practice.checkAgeRequired &&
                    <AgeConsent register={register} error={errors.ageConsent} value={values.ageConsent} required
                                age={practice.checkAgeValue}
                    />
            }
            {isPartialAvailable && <PartialPayment option20={paymentOptions.option20} option50={paymentOptions.option50} handlePaymentClick={handlePaymentClick} />}
            {isArrivalOverbooked &&
                <p className="member">{t('errors.someoneAlready')}</p>
            }
            {visible_installment && !!installment.length &&
                <Installment installment={installment} setInstallment={setInstallment}/>
            }
            <FormButton
                type="submit" value={t('common.next')}
                disabled={isArrivalOverbooked}
                onClick={() => {
                    sendAnalyticsEvents( 'name_main', 2);
                }}
            />
        </form>
    );
};

MembersScreen.propTypes = {
    practice: PropTypes.object,
    firstUserFields: PropTypes.object,
    order:PropTypes.object
};

export default MembersScreen;
