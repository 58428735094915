import './style.scss'
import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types'
import { t } from 'i18next';
import ElementWithAccessInCountries from '../../../../lib/utils/hocs/ElementWithAccessInCountries';
import ship from '../../../../styles/assets/images/subscriptions/ship.png';
import heart from '../../../../styles/assets/images/subscriptions/heart.png';
import diamond from '../../../../styles/assets/images/subscriptions/diamond.png';
import arrow from '../../../../styles/assets/images/sv/arrow.svg';
import { categorizationNumber } from "../../../../lib/utils/helpers/categorizationNumber"


const discountLess = 0;
const discountEqual = 1;
const discountRise = 2;

const discountStateHandler = {
  [discountLess]: 'discounts.discountLess',
  [discountEqual]: 'discounts.discountEqual',
  [discountRise]: 'discounts.discountRise',
}

const discountStateImageHandler = {
  [discountLess]: heart,
  [discountEqual]: ship, 
  [discountRise]: diamond,
}

const arrowRight = <div className='d-inline'><img src={arrow}/></div>

const SubscriptionInfo = ({
    currentSubscriptionData,
    subscription,
    depositSum,
    currency,
}) => {

    const [discountState, setDiscountState] = useState();

    useEffect(() => {
      if (currentSubscriptionData?.luch_individual_discount_size === subscription?.luch_individual_discount_size &&
        currentSubscriptionData?.keel_all_luch_group_discount_size === subscription?.keel_all_luch_group_discount_size &&
        currentSubscriptionData?.race_discount_size === subscription?.race_discount_size) {
        setDiscountState(discountEqual);

        return;
      }
      if (currentSubscriptionData?.luch_individual_discount_size > subscription?.luch_individual_discount_size ||
        currentSubscriptionData?.keel_all_luch_group_discount_size > subscription?.keel_all_luch_group_discount_size ||
        currentSubscriptionData?.race_discount_size > subscription?.race_discount_size) {
        setDiscountState(discountLess);
      } else {
        setDiscountState(discountRise);
      }
    }, [subscription, currentSubscriptionData])

    return (
        <div className='subscription_info_component'>
            <div className='mb-5'>
                <div className='input-label'>{t('discounts.discountCurrentOn')}</div> 
                <b className='subscription_info_label_amount'>{categorizationNumber(subscription?.nominal_value)}&nbsp;{currency}</b> 
                <div className='subscription_info_label_text'>{t('discounts.discountWillUpOn')}</div>
            </div>
            <div className='input-label'>{t('discounts.discountSubscriptions')}</div> 
            <div className={`d-flex flex-wrap ${discountState === discountLess ? 'warning' : discountState === discountRise ? 'success' : 'equality'} ${!depositSum ? 'equality' : ''}`}>
                <div className='subscription_info_discount'>
                {!!depositSum && (<><div className='d-inline text-dark'>{currentSubscriptionData?.keel_all_luch_group_discount_size}%</div> {arrowRight}</>)} {subscription?.keel_all_luch_group_discount_size}%
                    <div className='price_lable'>{t('discounts.discountOnYachtLuch')}</div>
                </div>
                <ElementWithAccessInCountries hideInCountries={['EN']}>
                  <>
                    <div className='subscription_info_discount'>
                      {!!depositSum && (<><div className='d-inline text-dark'>{currentSubscriptionData?.luch_individual_discount_size}%</div> {arrowRight}</>)} {subscription?.luch_individual_discount_size}%
                      <div className='price_lable'>{t('discounts.discountOnGroupYachtLuch')}</div>
                    </div>
                    <div className='subscription_info_discount'>
                      {!!depositSum && (<><div className='d-inline text-dark'>{currentSubscriptionData?.race_discount_size}%</div> {arrowRight}</>)} {subscription?.race_discount_size}%
                      <div className='price_lable'>{t('discounts.discountOnRace')}</div>
                    </div>
                    <div className='subscription_info_discount'>
                      {!!depositSum && (<><div className='d-inline text-dark'>{currentSubscriptionData?.luch_individual_discount_size}%</div> {arrowRight}</>)} <div className='d-inline'>{subscription?.luch_individual_discount_size}%</div>
                      <div className='price_lable'>{t('discounts.discountOnIndividualLuch')}</div>
                    </div>
                  </>
                </ElementWithAccessInCountries>
                {!!subscription?.cup_discount_size && <div className='subscription_info_discount'>
                  {!!depositSum && (<><div className='d-inline text-dark'>{currentSubscriptionData?.cup_discount_size}%</div> {arrowRight}</>)} {subscription?.cup_discount_size}%
                    <div className='price_lable'>{t('discounts.discountOnCup')}</div>
                  </div>}
                {!!~discountState && !!depositSum && <div className='subscription_info_discount_message'>
                    <div className='mr-2 icon-wrapper'>
                      <img width='18px' height='18px' src={discountStateImageHandler[discountState]}/>
                    </div>{t(discountStateHandler[discountState])}
                  </div>}
            </div>
        </div>
    )
}

SubscriptionInfo.propTypes = {
    currentSubscriptionData: PropTypes.object,
    subscription: PropTypes.object,
    currencies: PropTypes.array,
    currency: PropTypes.string,
}

export default SubscriptionInfo