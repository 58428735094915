import './style.scss';
import {categorizationNumber} from "../../../lib/utils/helpers/categorizationNumber";
import {normalizeCurrency} from "../../../lib/utils/helpers";
import {useEffect, useState} from "react";
import {usePublicEndpoints} from "../../../lib/api/usePublicEndpoints";
import {t} from "i18next";
import ElementWithAccessInCountries from "../../../lib/utils/hocs/ElementWithAccessInCountries";

const SubscriptionDetails = ({subscriptionData}) => {
    const {
        deposit_sum,
        subscription_price_currency,
        keel_all_luch_group_discount_size,
        luch_individual_discount_size,
        race_discount_size,
        cup_discount_size,
    } = subscriptionData;

    const { getCurrencies } = usePublicEndpoints();
    const [currencies, setCurrencies] = useState([]);
    const currency = normalizeCurrency(subscription_price_currency, currencies) || '';

    useEffect(() => {
        getCurrencies()
            .then(res => setCurrencies(res))
    }, []);

    return (
        <div className='subscription-details'>
            <div className="subscription-balance">
                <div className="input-label">{t('subscriptions.subscriptionRemainder')}</div>
                <div class="subscription-balance__amount">
                    {categorizationNumber(deposit_sum)}&nbsp;{currency}
                </div>
            </div>
            <div className="subscription-discounts">
                <div className="input-label">{t('discounts.discountSubscriptions')}</div>
                <div class="subscription-discounts__wrap">
                    <div className="subscription-discounts__item">
                        <div className="subscription-discounts__amount">{keel_all_luch_group_discount_size}%</div>
                        <div className="subscription-discounts__label">{t('discounts.discountOnYachtLuch')}</div>
                    </div>
                    <div className="subscription-discounts__item">
                        <div className="subscription-discounts__amount">{luch_individual_discount_size}%</div>
                        <div className="subscription-discounts__label">{t('discounts.discountOnGroupYachtLuch')}</div>
                    </div>
                    <ElementWithAccessInCountries hideInCountries={['EN']}>
                        <div className="subscription-discounts__item">
                            <div className="subscription-discounts__amount">{race_discount_size}%</div>
                            <div className="subscription-discounts__label">{t('discounts.discountOnRace')}</div>
                        </div>
                    </ElementWithAccessInCountries>
                    <div className="subscription-discounts__item">
                        <div className="subscription-discounts__amount">{luch_individual_discount_size}%</div>
                        <div className="subscription-discounts__label">{t('discounts.discountOnIndividualLuch')}</div>
                    </div>
                    <div className="subscription-discounts__item">
                        <div className="subscription-discounts__amount">{cup_discount_size}%</div>
                        <div className="subscription-discounts__label">{t('discounts.discountOnCup')}</div>
                    </div>
                </div>
            </div>
            <div class="divider no-margin mt-5 mb-5"></div>
            <div class="history-block">
                <div class="history-block__title">История</div>
                <div class="history-block__wrap">
                    <div class="history-block__year">2025</div>

                    <div class="history-block__item">
                        <div class="history-block__info">
                            <div class="history-block__name">Тренировка на SV20, Строгино</div>
                            <div class="history-block__details">17 июля • 08:00 • 2 места</div>
                        </div>
                        <div class="history-block__price">9 000 ₽</div>
                    </div>
                    <div class="history-block__item">
                        <div class="history-block__info">
                            <div class="history-block__name">Тренировка на SV20, Строгино</div>
                            <div class="history-block__details">17 июля • 08:00 • 2 места</div>
                        </div>
                        <div class="history-block__price">9 000 ₽</div>
                    </div>

                    <div class="history-block__year">2024</div>

                    <div class="history-block__item">
                        <div class="history-block__info">
                            <div class="history-block__name">Тренировка на SV20, Строгино</div>
                            <div class="history-block__details">17 июля • 08:00 • 2 места</div>
                        </div>
                        <div class="history-block__price">9 000 ₽</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SubscriptionDetails