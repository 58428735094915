import { injected, token } from 'brandi';
import { makeAutoObservable } from 'mobx';
import container from '../../../../lib/ioc';
import { GlobalModelStoreToken, IGlobalStateModel } from '../../../../lib/models/global';
import { AuthModelStoreToken, IAuthModel } from '../../../../entities/auth/model';

interface IPasswordRestoreFormModel {
  isEmailSent: boolean,
  error: string,

  setIsEmailSent: (v: boolean) => void,
  setError: (v: string) => void,
  onSubmit: (email: string, generateCaptcha: (cb: (token: string) => void) => void) => void,
}

export default class PasswordRestoreFormModel implements IPasswordRestoreFormModel {
  isEmailSent = false;
  error = '';

  constructor(
    private globalM: IGlobalStateModel,
    private authM: IAuthModel,
  ) {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  setIsEmailSent(v: boolean) {
    this.isEmailSent = v;
  }

  setError(v: string) {
    this.error = v;
  }

  onSubmit(email: string, generateCaptcha: (cb: (token: string) => void) => void) {
    generateCaptcha((captcha) => {
        this.globalM.set({ isLoading: true });
        this.authM.forgotPassword(email, captcha).then(() => {
            this.isEmailSent = true;
        })
      .catch(e => {
        const errorId = e.response?.data?.message[0]?.messages[0]?.id;
        if (errorId === 'Auth.form.error.user.not-exist') {
          this.error = 'emailError';
        }
        console.log('An error occurred:', e.response);
      })
      .finally(() => {
        this.globalM.set({ isLoading: false });
      })
    })
  }
}


export const PasswordRestoreFormModelStoreToken = token<IPasswordRestoreFormModel>('PasswordRestoreFormModelStoreToken');

container.bind(PasswordRestoreFormModelStoreToken).toInstance(PasswordRestoreFormModel).inSingletonScope();

injected(PasswordRestoreFormModel, GlobalModelStoreToken, AuthModelStoreToken);
