import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import ArrivalSelector from "./ArrivalSelector";
import {NumberParam, StringParam, useQueryParams} from 'use-query-params';
import PresetArrival from "./PresetArrival";
import FormButton from "../../../generic/buttons/main";
import PriceBlock from "../Price";
import { isProductNotExpired } from "../../../../lib/utils/helpers";
import {useParams} from "react-router-dom";
import {useLogin} from "../../../../lib/utils/hooks/useLogin";
import {BASE_SILAVETRA_URL} from "../../../../lib/api/globalState";
import {t} from "i18next";
import AlertDismissibleExample from '../../../common/GlobalSaleAlert';
import { useInjection } from 'brandi-react';
import { GlobalSaleModelToken } from '../../../../lib/models/availablePriceFromSale';
import {sendAnalyticsEvents} from "../../../../lib/utils/analytics";

const Schedule = ({updateData, pickedPractice, people, practices, currencies, isPracticePreset, setFirstUserFields}) => {
    const {practiceURL} = useParams();

    const globalSaleM = useInjection(GlobalSaleModelToken);

    const [isPracticeOverbooked, setIsPracticeOverbooked] = useState(false);

    const {ensureLoggedIn} = useLogin()

    const [query, setQuery] = useQueryParams({
        step: NumberParam,
        practice: StringParam,
    });

    const submitWithValidation = async () => {
        const places = pickedPractice?.availableSits

        if (places < people) {
            setIsPracticeOverbooked(true);
            return;
        }
        ensureLoggedIn((isUserAlreadyLoggedIn, user) => {
            if (!isUserAlreadyLoggedIn) {
                setFirstUserFields(user)
            }
            setQuery({step: 2})
        })
    }

    useEffect(() => {
        setIsPracticeOverbooked(() => pickedPractice?.availableSits < people)
    }, [query.practice, practiceURL])

    const isButtonDisabled = !query.practice && !practiceURL || isPracticeOverbooked
    const noSeatsAvailable = pickedPractice?.availableReservedSits === 0;
    const isShowOnMainButton = (!practices.length && !isPracticePreset)

    return <>
        <div className={"schedule"}>
            {practiceURL
                ? <PresetArrival arrival={pickedPractice}/>
                : <ArrivalSelector arrivals={practices} updateData={updateData}/>
            }

            <AlertDismissibleExample />
            {!noSeatsAvailable && !isShowOnMainButton && <FormButton
                disabled={isButtonDisabled || !isProductNotExpired(pickedPractice?.startDate)}
                value={isProductNotExpired(pickedPractice?.startDate) ? t("common.next") : t("common.bookingClosed")}
                onClick={() => {
                    sendAnalyticsEvents( 'date_main', 1);
                    submitWithValidation()
                }}
                className={"d-none d-lg-block stickyButton"}
            />}
            {noSeatsAvailable && <>
                <p className="mt-3 d-none d-lg-block">{t('common.placesEnded')}</p>
            </>}
            {(query.practice || practiceURL) && !isShowOnMainButton && (
                <PriceBlock people={people} arrival={pickedPractice} onSubmit={submitWithValidation} price={globalSaleM.calculatePrice(pickedPractice, people) || pickedPractice?.priceValue} currencies={currencies}
                            selectedCurrency={pickedPractice?.priceCurrency}
                            isButtonDisabled={isButtonDisabled || !isProductNotExpired(pickedPractice?.startDate)}
                            noSeatsAvailable={noSeatsAvailable}
                            buttonValue={isProductNotExpired(pickedPractice?.startDate) ? t('common.next') : t('common.bookingClosed')}
                />
            )}
            {isPracticeOverbooked &&
                <p>{t('errors.someoneAlready')}</p>
            }
            {isShowOnMainButton && <>
                <p className={`${isPracticePreset && "d-none"} m-3 d-lg-block`}>
                    {t('common.placesEnded')}
                </p>
                <FormButton
                    value={t("common.toMain")}
                    className={`${isPracticePreset && "d-none"} d-lg-block`}
                    onClick={() => window.location.href = BASE_SILAVETRA_URL + "/travel"}
                />
            </>
            }
        </div>

    </>
};

Schedule.propTypes = {
    people: PropTypes.number,
    updateData: PropTypes.func,
    practices: PropTypes.array,
    currencies: PropTypes.array,
    pickedPractice: PropTypes.object,
};

export default Schedule;
